/*
 * @Description: 系统默认菜单
 * @Author: Pancras
 * @Date: 2019-09-09 10:11:38
 * @LastEditors: Pancras
 * @LastEditTime: 2022-01-04 17:47:03
 */
// 菜单 顶栏
export default [
  { path: '/index', title: '首页', icon: 'home' }
]
