export default {
  namespaced: true,
  state: {
    siteId: 1 // 当前选择的站点 ID
  },
  actions: {
    /**
     * @description 设置站点
     * @param {Object} context
     * @param siteId 站点ID
     */
    setSite ({ state, dispatch }, siteId) {
      // store 赋值
      state.siteId = siteId
      // 持久化
      dispatch('d2admin/db/set', {
        dbName: 'sys',
        path: 'user.siteId',
        value: siteId,
        user: true
      }, { root: true })
    },
    /**
     * @description  加载站点
     * @param {Object} context
     * @param {String} siteId 站点Id
     */
    load ({ state, dispatch }, siteId) {
      return new Promise(async resolve => {
        // 基本信息
        state.siteId = await dispatch('d2admin/db/get', {
          dbName: 'sys',
          path: 'user.siteId',
          defaultValue: 1,
          user: true
        }, { root: true })
        // end
        resolve()
      })
    }
  }
}
