
/*
 * @Description: 广播消息管理接口文件
 * @Author: pigx code generator
 * @Date: 2020-01-13 13:07:36
 * @LastEditors: Pancras
 * @LastEditTime: 2021-11-03 13:45:28
 */
import request from '@/plugin/axios' // axios 请求插件

/**
 * _AddSysMessage
 * @description 新增广播消息
 * @param {Object} SysMessageDto 数据传输对象
 */
export function _AddSysMessage (sysMessageDto) {
  return request({
    url: '/admin/message',
    method: 'post',
    data: sysMessageDto
  })
}

/**
 * _DelSysMessage
 * @description 删除广播消息
 * @param {Number} id 主键
 */
export function _DelSysMessage (id) {
  return request({
    url: '/admin/message/' + id,
    method: 'delete'
  })
}

/**
 * _PutSysMessage
 * @description 更新广播消息
 * @param {Object} SysMessageDto 数据传输对象
 */
export function _PutSysMessage (sysMessageDto) {
  return request({
    url: '/admin/message',
    method: 'put',
    data: sysMessageDto
  })
}

/**
 * @name: _PublishSysMessage
 * @description: 发布|取消发布广播消息
 * @param {Number} id 主键
 */
export function _PublishSysMessage (id) {
  return request({
    url: '/admin/message/' + id + '/publish',
    method: 'get'
  })
}

/**
 * @name: _AllReceived
 * @description: 全部标记已读
 */
export function _AllReceived () {
  return request({
    url: '/admin/message/all-received',
    method: 'get'
  })
}

/**
 * _GetSysMessage
 * @description 查询广播消息信息 [权限内]
 * @param {Number} id 主键
 */
export function _GetSysMessage (id) {
  return request({
    url: '/admin/message/' + id,
    method: 'get'
  })
}

/**
 * _GetSysMessageSelf
 * @description 查询个人广播消息信息 [VO] [权限内]
 * @param {Number} id 主键
 */
export function _GetSysMessageSelf (id) {
  return request({
    url: '/admin/message/' + id + '/self',
    method: 'get'
  })
}

/**
 * _GetSysMessageUnreceivedSelf
 * @description 查询个人未读站内信
 */
export function _GetSysMessageUnreceivedSelf (receiverType) {
  return request({
    url: '/admin/message/unreceived/self',
    method: 'get',
    params: { receiverType }
  })
}

/**
 * _GetSysMessagePage
 * @description 分页查询个人站内信分页信息 [VO] [权限内]
 * @param {Object} page 分页对象
 * @param {Object} sysMessageVo 查询参数 [SysMessageVo]
 */
export function _GetSysMessageSelfPage (page, sysMessageVo) {
  return request({
    url: '/admin/message/page/self',
    method: 'get',
    params: { ...page, ...sysMessageVo }
  })
}

/**
 * _GetSysMessagePage
 * @description 分页查询广播消息分页信息 [VO] [权限内]
 * @param {Object} page 分页对象
 * @param {Object} sysMessageVo 查询参数 [SysMessageVo]
 */
export function _GetSysMessagePage (page, sysMessageVo) {
  return request({
    url: '/admin/message/page',
    method: 'get',
    params: { ...page, ...sysMessageVo }
  })
}

export default {
  _AddSysMessage, _DelSysMessage, _PutSysMessage, _GetSysMessage, _PublishSysMessage, _AllReceived, _GetSysMessageUnreceivedSelf, _GetSysMessageSelf, _GetSysMessageSelfPage, _GetSysMessagePage
}
