<!--
 * @Description: 用一句话描述文件的作用
 * @Author: Pancras
 * @Date: 2021-12-20 11:14:34
 * @LastEditors: Pancras
 * @LastEditTime: 2022-07-20 13:58:10
-->
<template>
  <div id="app"
       @click="clicked">
    <router-view />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import util from '@/libs/util'
export default {
  name: 'app',
  data () {
    return {
      loginTime: new Date().getTime(), // 最后一次点击的时间
      currnetTime: new Date().getTime(), // 当前时间
      timeout: 30 * 60 * 1000 // 超时时间30min
    }
  },
  watch: {
  },
  created () {

  },
  mounted () {
    // 每秒检查一次是否长时间未点击
    window.setInterval(this.checkTimeout, 1000)
  },

  computed: {
    ...mapState('d2admin', {
      isLock: state => state.lock.isLock, // 锁屏状态
      lockPasswd: state => state.lock.lockPasswd, // 锁屏密码
      siteId: state => state.site.siteId // 站点 ID
    })
  },

  methods: {
    ...mapActions({
      logout: 'd2admin/account/logout',
      setLock: 'd2admin/lock/setLock',
      setSite: 'd2admin/site/setSite'
    }),

    /**
      * clicked
      * @description 用户点击页面刷新点击时间
    */
    clicked () {
      this.loginTime = new Date().getTime()
    },

    /**
      * checkTimeout
      * @description 检查是否超时
    */
    checkTimeout () {
      // 锁屏状态不做任何操作
      if (this.isLock) {
        return
      }
      this.currnetTime = new Date().getTime()
      if (this.currnetTime - this.loginTime > this.timeout) {
        // 未设置锁屏密码,超时退出登陆
        if (util.isNull(this.lockPasswd)) {
          this.logout()
        } else { // 若有锁屏密码,进入锁屏页面
          this.setLock()
          setTimeout(() => {
            // 重定向至锁屏页
            this.$router.push({
              name: 'lock',
              query: {
                redirect: this.$route.fullPath
              }
            })
          }, 100)
        }
      }
    }
  }
}
</script>

<style lang="scss">
@import "~@/assets/style/public-class.scss";
</style>
