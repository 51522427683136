/*
 * @Description: 用一句话描述文件的作用
 * @Author: Pancras
 * @Date: 2019-09-12 00:03:53
 * @LastEditors: Pancras
 * @LastEditTime: 2022-01-05 12:38:56
 */
// 设置文件
import setting from '@/setting.js'
import { _GetUserMenu } from '@/api/admin/menu'
import util from '@/libs/util'
import headerMenu from '@/menu/header'

/**
 * @description 根据预定义菜单 ID 过滤无用菜单
 * @param {JsonArray} menus 服务端 Menu
 */
function fillterMenu (menus) {
  // 从配置文件中获取预定义菜单 id
  let fillterIds = setting.menu.fillterIds
  let list = []
  menus.forEach(m => {
    if (fillterIds.includes(m.originalId)) {
      list.push(m)
    } else {
      if (m.children && m.children.length > 0) {
        let tem = fillterMenu(m.children)
        if (tem & tem.length > 0) {
          list.push(tem)
        }
      }
    }
  })
  return list
}

/**
 * @description 将服务端菜单格式化为标准格式
 * @param {JsonArray} menu 服务端 Menu
 */
function convertMenu (menus) {
  let list = []
  menus.forEach(m => {
    const item = {
      id: m.id,
      path: (/^https:\/\/|http:\/\//.test(m.path)) ? '/' + m.id : m.path,
      title: m.name,
      icon: m.icon,
      component: (/^https:\/\/|http:\/\//.test(m.path)) ? '/' + m.id : m.path
    }
    if (m.children && m.children.length > 0) {
      item.children = convertMenu(m.children, m.path)
    }
    list.push(item)
  })
  return list
}

export default {
  namespaced: true,
  state: {
    // 顶栏菜单
    header: [],
    // 侧栏菜单
    aside: [],
    // 侧栏子菜单
    asideSub: [],
    // 用户菜单是否加载标识
    isLoaded: false
  },
  actions: {
    /**
     * @description 设置顶栏菜单
     * @param {Object} state state
     * @param {Array} menu menu setting
     */
    headerSet ({ state, dispatch }, menu) {
      return new Promise(async resolve => {
        // store 赋值
        state.header = menu
        // 持久化
        await dispatch('d2admin/db/set', {
          dbName: 'sys',
          path: 'user.header',
          value: menu,
          user: true
        }, { root: true })
        // end
        resolve()
      })
    },
    /**
     * @description 设置侧边栏菜单
     * @param {Object} state state
     * @param {Array} menu menu setting
     */
    asideSet ({ state, dispatch }, menu) {
      state.aside = menu
      return new Promise(async resolve => {
        // store 赋值
        state.aside = menu
        // 持久化
        await dispatch('d2admin/db/set', {
          dbName: 'sys',
          path: 'user.aside',
          value: menu,
          user: true
        }, { root: true })
        // end
        resolve()
      })
    },
    /**
     * @description 设置侧边栏子菜单
     * @param {Object} state state
     * @param {Array} menu menu setting
     */
    asideSubSet ({ state, dispatch }, menu) {
      return new Promise(async resolve => {
        // store 赋值
        state.asideSub = menu
        // 持久化
        await dispatch('d2admin/db/set', {
          dbName: 'sys',
          path: 'user.asideSub',
          value: menu,
          user: true
        }, { root: true })
        // end
        resolve()
      })
    },

    /**
     * @description 加载菜单
     * @param {Object} context
     */
    menuLoad ({ state, dispatch }) {
      return new Promise(async resolve => {
        // 否加载
        state.isLoaded = true
        // 加载顶部菜单
        state.header = await dispatch('d2admin/db/get', {
          dbName: 'sys',
          path: 'user.header',
          defaultValue: {},
          user: true
        }, { root: true })

        // 加载侧边菜单
        state.aside = await dispatch('d2admin/db/get', {
          dbName: 'sys',
          path: 'user.aside',
          defaultValue: {},
          user: true
        }, { root: true })

        // 加载侧边子菜单
        state.asideSub = await dispatch('d2admin/db/get', {
          dbName: 'sys',
          path: 'user.asideSub',
          defaultValue: {},
          user: true
        }, { root: true })

        resolve()
      })
    },
    /**
     * @description 获取用户菜单
     * @param {Object} state vuex state
     * @param {*} info info
     */
    getUserMenu ({ state, dispatch, commit }) {
      return new Promise((resolve, reject) => {
        // 调用获取用户菜单 API
        _GetUserMenu().then(async res => {
          if (util.isNull(res)) {
            return reject(new Error('您当前无任何菜单权限!'))
          }
          const data = fillterMenu(res)
          // 将系统定义菜单与后台菜单合并
          const menu = [].concat(headerMenu, convertMenu(data))
          // 存储用户原始菜单
          dispatch('d2admin/user/setMenu', data, { root: true })
          // 初始化顶栏菜单(首页排除)
          dispatch('headerSet', menu)
          // 初始化侧边栏菜单
          dispatch('asideSet', menu[1].children)
          // 初始化侧边栏子菜单
          dispatch('asideSubSet', menu[1].children[0].children)
          // 初始化菜单搜索功能
          commit('d2admin/search/init', menu, { root: true })
          // 初始化路由
          await dispatch('d2admin/router/generatorRoutes', data, { root: true })
          resolve(data)
        }).catch(err => {
          reject(err)
        })
      })
    }
  }
}
