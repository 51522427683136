/*
 * @Description: 用一句话描述文件的作用
 * @Author: Pancras
 * @Date: 2019-09-16 10:25:08
 * @LastEditors: Pancras
 * @LastEditTime: 2022-01-05 19:51:16
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import store from '@/store/index'
import util from '@/libs/util.js'
import routes from './routes'
// import { frameInRoutes, errorPageRoute } from '@/router/routes'

// 解决当前 vue-router 版本多次点击报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

// 导出路由 在 main.js 里使用
const router = new VueRouter({
  routes
})

// 自定义添加路由方法
router.$addRoutes = (params) => {
  router.matcher = new VueRouter().matcher
  params.forEach(route => {
    router.addRoute(route)
  })
}

/**
 * 路由拦截
 * 权限验证
 */
router.beforeEach((to, from, next) => {
  // 进度条
  NProgress.start()
  // 关闭搜索面板
  store.commit('d2admin/search/set', false)
  // 判断当前路由否需要登录验证
  // 获取用户 token
  const token = util.getToken()
  if (token && token !== 'undefined') {
    let isLock = store.state.d2admin.lock.isLock
    if (isLock && to.path !== '/lock') {
      next({ path: '/lock' })
    } else {
      if (store.state.d2admin.router.asyncRoutes.length === 0) {
        store.dispatch('d2admin/account/getUserInfo').then(() => {
          // 加载用户缓存菜单
          store.dispatch('d2admin/user/loadMenu').then(async res => {
            // 生成动态路由
            store.dispatch('d2admin/router/generatorRoutes', res, { root: true })
            // 加载用户数据
            await store.dispatch('d2admin/account/load')
            // 下一个路由
            next({ ...to, replace: true })
          })
        }).catch((err) => {
          this.$message.error(err.message)
          // 错误,跳转到登陆页面
          store.dispatch('d2admin/account/logout', { vm: router.app })
        })
      } else {
        next()
      }
    }
  } else {
    // 如果访问页面是登陆页面,则直接放行
    if (to.path === '/login') {
      next()
    } else {
      // 跳转至登录页面
      next({
        name: 'login'
      })
    }
    // https://github.com/d2-projects/d2-admin/issues/138
    NProgress.done()
  }
})

router.afterEach(to => {
  // 进度条
  NProgress.done()
  // 多页控制 打开新的页面
  store.dispatch('d2admin/page/open', to)
  // 更改标题
  util.title(to.meta.title)
})

export default router
