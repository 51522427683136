<template>
  <div class="lay-container-layer"
       :class=" {grayMode: grayActive}">
    <!-- 半透明遮罩 开始 -->
    <div class="lay-container-mask"></div>
    <!-- 半透明遮罩 结束 -->
    <!-- 布局容器 开始 -->
    <div class="lay-container"
         flex="box:first">
      <!-- 侧边栏 开始 -->
      <div class="lay-aside"
           flex="dir:top cross:center box:first">
        <!-- 系统 LOGO 开始 -->
        <div class="lay-aside-logo"
             flex="main:center cross:center">
          <img v-if="tenantLogo && tenantLogo !== 'null'" :src="tenantLogo"/>
          <img v-else :src="`${$baseUrl}image/logo.png`"/>
        </div>
        <!-- 系统 LOGO 结束 -->
        <!-- 侧边栏菜单 开始 -->
        <div class="lay-aside-menu"
             flex="box:first">
          <!-- 侧边主菜单 开始 -->
          <div class="lay-aside-menu-left">
            <el-menu ref="asideMenu"
                     :default-active="asideActive"
                     @select="handleAsideMenuSelect">
              <el-menu-item v-for="(menu,index) in asideMenu"
                            :key="index"
                            :index="menu.path">
                <span slot="title">{{ menu.title }}</span>
              </el-menu-item>
            </el-menu>
          </div>
          <!-- 侧边主菜单 结束 -->
          <!-- 侧边子菜单 开始 -->
          <div class="lay-aside-menu-right">
            <el-menu ref="asideSubMenu"
                     :default-active="asideSubActive"
                     @select="handleSubMenuSelect">
              <el-menu-item v-for="(menu,index) in asideSubMenu"
                            :key="index"
                            :index="menu.path">
                <i :class="`fa fa-${menu.icon}`"></i>
                <span slot="title">{{ menu.title }}</span>
              </el-menu-item>
            </el-menu>
          </div>
          <!-- 侧边子菜单 结束 -->
        </div>
        <!-- 侧边栏菜单 结束 -->
      </div>
      <!-- 侧边栏 结束 -->
      <!-- 主体区域 开始 -->
      <div class="lay-main"
           flex="dir:top box:first">
        <!-- 主体菜单 开始 -->
        <div class="lay-main-header"
             flex="cross:center box:last">
          <div class="lay-main-header-menu">
            <el-menu ref="headerMenu"
                     mode="horizontal"
                     :default-active="headerActive"
                     @select="handleHeaderMenuSelect">
              <el-menu-item v-for="(menu,index) in headerMenu"
                            :key="index"
                            :index="menu.path">
                <i :class="`fa fa-${menu.icon}`"></i>
                <span slot="title">{{ menu.title }}</span>
              </el-menu-item>
            </el-menu>
          </div>
          <div class="lay-main-header-icon"
               flex="cross:center">
            <d2-header-site/>
            <d2-header-log/>
            <d2-header-fullscreen/>
            <d2-header-message/>
            <d2-header-lock/>
            <d2-header-size/>
            <d2-header-user/>
          </div>
        </div>
        <!-- 主体菜单 结束 -->
        <transition name="fade-scale">
          <div class="lay-main-body"
               flex="dir:top box:first">
            <!-- 选项卡 开始 -->
            <div class="lay-main-body-tabs">
              <d2-tabs/>
            </div>
            <!-- 选项卡 结束 -->
            <!-- 主体内容 开始 -->
            <div class="lay-main-body-content">
              <transition :name="transitionActive ? 'fade-transverse' : ''">
                <keep-alive :include="keepAlive">
                  <router-view/>
                </keep-alive>
              </transition>
            </div>
            <!-- 主体内容 结束 -->
          </div>
        </transition>
      </div>
      <!-- 主体区域 结束 -->
    </div>
    <!-- 布局容器 结束 -->
  </div>

</template>

<script>
import d2Tabs from './components/tabs'
import d2HeaderFullscreen from './components/header-fullscreen'
import d2HeaderMessage from './components/header-message'
import d2HeaderLock from './components/header-lock'
import d2HeaderSize from './components/header-size'
import d2HeaderUser from './components/header-user'
import d2HeaderLog from './components/header-log'
import d2HeaderSite from './components/header-site'
import { mapState, mapActions } from 'vuex'
import util from '@/libs/util'
import setting from '@/setting'
import * as SockJS from 'sockjs-client'
import Stomp from 'stomp-websocket'
import cookies from '@/libs/util.cookies'

export default {
  name: 'd2-layout-header-aside',
  components: {
    d2Tabs,
    d2HeaderFullscreen,
    d2HeaderLock,
    d2HeaderMessage,
    d2HeaderSize,
    d2HeaderUser,
    d2HeaderLog,
    d2HeaderSite
  },
  data () {
    return {
      // 当前选中菜单
      headerActive: '',
      asideActive: '',
      asideSubActive: '',
      // 刷新token锁
      refreshLock: false,
      // 刷新token的时间
      refreshTime: '',
      // 计时器
      timer: '',
      tenantLogo: cookies.get('tenantLogo')
    }
  },
  created () {
    // 实时检测刷新token
    // this.refreshToken()
  },

  destroyed () {
    // clearInterval(this.refreshTime)
    clearInterval(this.timer)
    /*
    * 默认关闭websocket，如需工作流通知，则开启
    * this.disconnect()
    */
    this.disconnect()
  },

  mounted () {
    /*
     * 默认关闭websocket，如需工作流通知，则开启
     * this.initWebSocket()
    */
    this.initWebSocket()
  },

  computed: {
    ...mapState('d2admin', {
      headerMenu: state => state.menu.header,
      asideMenu: state => state.menu.aside,
      asideSubMenu: state => state.menu.asideSub,
      keepAlive: state => state.page.keepAlive,
      grayActive: state => state.gray.active,
      transitionActive: state => state.transition.active,
      info: state => state.user.info
    })
  },
  methods: {
    ...mapActions({
      _RefreshToken: 'd2admin/account/refreshToken',
      asideMenuSet: 'd2admin/menu/asideSet',
      asideSubMenuSet: 'd2admin/menu/asideSubSet'
    }),

    /**
     * handleHeaderMenuSelect
     * @description 顶部菜单点击
     * @param {Object} index  索引
     * @param {Object} indexPath 索引对应 Path
     */
    handleHeaderMenuSelect (index, indexPath) {
      if (!util.isEqual('/index', index)) {
        this.headerMenu.forEach(menu => {
          if (util.isEqual(menu.path, index)) {
            this.asideMenuSet(menu.children)
            this.asideSubMenuSet(menu.children[0].children)
          }
        })
      } else {
        this.$router.push({
          path: index
        })
      }
    },

    /**
     * handleAsideMenuSelect
     * @description 侧边栏菜单点击
     * @param {Object} index  索引
     * @param {Object} indexPath 索引对应 Path
     */
    handleAsideMenuSelect (index, indexPath) {
      this.asideMenu.forEach(menu => {
        if (util.isEqual(menu.path, index)) {
          this.asideSubMenuSet(menu.children)
        }
      })
    },

    /**
     * handleSubMenuSelect
     * @description 侧边栏子菜单点击
     * @param {Object} index  索引
     * @param {Object} indexPath 索引对应 Path
     */
    handleSubMenuSelect (index, indexPath) {
      if (/^https:\/\/|http:\/\//.test(index)) {
        util.open(index)
      } else {
        this.$router.push({
          path: index
        })
      }
    },

    /**
     * filterMenu
     * @description 重新设置侧菜单
     * @param {Object} levelOnePath  顶部菜单 Path
     * @param {Object} levelTwoPath 侧边菜单 Path
     */
    filterMenu (levelOnePath, levelTwoPath) {
      // 获取侧边栏菜单
      for (let i = 0; i < this.headerMenu.length; i++) {
        let levelTwoMenu = []
        if (util.isEqual(this.headerMenu[i].path, levelOnePath)) {
          levelTwoMenu = this.headerMenu[i].children
          this.asideMenuSet(levelTwoMenu)
          // 获取侧边栏子菜单
          for (let j = 0; j < levelTwoMenu.length; j++) {
            let levelTreeMenu = []
            if (util.isEqual(levelTwoMenu[j].path, levelTwoPath)) {
              levelTreeMenu = levelTwoMenu[j].children
              this.asideSubMenuSet(levelTreeMenu)
              break
            }
          }
          break
        }
      }
    },

    /**
     * refreshToken
     * @description 定时检测刷新token
     */
    refreshToken () {
      this.refreshTime = setInterval(() => {
        let token = util.getToken()
        if (util.isNull(token)) {
          return
        }
        let expiresOn = token.expriresOn// 获取过期时间(毫秒数)
        let nowMillisecond = new Date().getTime()// 时间戳毫秒数
        // 过期前一分钟刷新 token
        if ((expiresOn - nowMillisecond) <= 60 * 1000 && !this.refreshLock) {
          this.refreshLock = true
          this._RefreshToken(token.refreshToken).catch(() => {
            clearInterval(this.refreshTime)
          })
          this.refreshLock = false
        }
      }, 10000)
    },

    /**
     * initWebSocket
     * @description 初始化 WebSocket
     */
    initWebSocket () {
      this.connection()
      const self = this
      // 断开重连机制,尝试发送消息,捕获异常发生时重连
      this.timer = setInterval(() => {
        try {
          self.stompClient.send('test')
        } catch (err) {
          console.log('断线了: ' + err)
          self.connection()
        }
      }, 5000)
    },

    /**
     * connection
     * @description WS 连接
     */
    connection () {
      const token = util.getToken()
      const TENANT_ID = setting.server.tenantId
      const headers = {
        'Authorization': 'Bearer ' + token.accessToken
      }
      // 建立连接对象
      this.socket = new SockJS('/act/ws')// 连接服务端提供的通信接口，连接以后才可以订阅广播消息和个人消息
      // 获取STOMP子协议的客户端对象
      this.stompClient = Stomp.over(this.socket)
      this.stompClient.debug = null
      // 向服务器发起websocket连接
      this.stompClient.connect(headers, () => {
        this.stompClient.subscribe('/task/' + this.info.username + '-' + TENANT_ID + '/remind', (msg) => { // 订阅服务端提供的某个topic;
          this.$notify({
            title: '协同提醒',
            type: 'warning',
            dangerouslyUseHTMLString: true,
            message: msg.body + '任务，请及时处理',
            offset: 60
          })
        })
      })
    },

    /**
     * disconnect
     * @description WS 断开连接
     */
    disconnect () {
      if (this.stompClient != null) {
        this.stompClient.disconnect()
        console.log('Disconnected')
      }
    }
  },

  watch: {
    '$route.matched': {
      handler (val) {
        // Path 转数组
        let path = val[val.length - 1].path.split('/')
        // 获取主菜单 Path
        let headerPath = path.slice(0, path.length - 3).join('/')
        // 获取侧菜单 Path
        let asidePath = path.slice(0, path.length - 2).join('/')

        // 更新侧边栏菜单
        this.filterMenu(headerPath, asidePath)

        // 更新菜单选中状态
        this.$nextTick(() => {
          this.$refs.headerMenu.activeIndex = headerPath
          this.$refs.asideMenu.activeIndex = asidePath
          this.$refs.asideSubMenu.activeIndex = val[val.length - 1].path
        })
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss">
// 注册主题
@import "~@/assets/style/layout.scss";
</style>
