/*
 * @Description: 版本提示
 * @Author: Pancras
 * @Date: 2019-10-21 10:13:41
 * @LastEditors: Pancras
 * @LastEditTime: 2021-11-02 18:05:24
 */
import util from '@/libs/util.js'

export default {
  namespaced: true,
  mutations: {
    /**
     * @description 显示版本信息
     * @param {Object} state state
     */
    versionShow () {
      util.log.capsule('ASSP', `v${process.env.VUE_APP_VERSION}`)
    }
  }
}
