<template>
  <div>
    <el-tooltip effect="dark"
                content="锁定屏幕"
                placement="bottom">
      <el-button class="d2-mr btn-text can-hover"
                 type="text"
                 @click="handleLock">
        <d2-icon name="lock"
                 style="font-size: 19px;" />
      </el-button>
    </el-tooltip>
    <el-dialog :visible.sync="visible"
               title="设置锁屏密码"
               width="40%"
               append-to-body>
      <el-form ref="form"
               :model="form"
               label-width="80px">
        <el-form-item :rules="[{ required: true, message: '锁屏密码不能为空'}]"
                      label="锁屏密码"
                      prop="passwd"
                      style="margin-bottom: 0px;">
          <el-input v-model="form.passwd"
                    placeholder="请输入锁屏密码"
                    show-password />
        </el-form-item>
      </el-form>
      <span slot="footer"
            class="dialog-footer">
        <el-button type="primary"
                   @click="handleSetLock">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import util from '@/libs/util'
export default {
  data () {
    return {
      visible: false,
      form: {
        passwd: ''
      }
    }
  },
  computed: {
    ...mapState('d2admin', {
      isLock: state => state.lock.isLock, // 锁屏状态
      lockPasswd: state => state.lock.lockPasswd// 锁屏密码
    })
  },

  methods: {
    ...mapActions('d2admin/lock', [
      'setLock', 'setLockPasswd'
    ]),

    /**
      * handleSetLock
      * @description 设置锁屏密码
    */
    handleSetLock () {
      this.$refs['form'].validate(valid => {
        if (valid) {
          this.setLockPasswd(this.form.passwd)// 设置锁屏密码
          this.handleLock()// 锁定屏幕
        }
      })
    },

    /**
      * handleLock
      * @description 锁定屏幕
    */
    handleLock () {
      // 未设置锁屏密码,先设置锁屏密码
      if (util.isNull(this.lockPasswd)) {
        this.visible = true
        return
      }
      // 设置锁屏状态
      this.setLock()
      setTimeout(() => {
        // 重定向至锁屏页
        this.$router.push({
          name: 'lock',
          query: {
            redirect: this.$route.fullPath
          }
        })
      }, 100)
    }
  }
}
</script>
