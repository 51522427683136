<!--
 * @Description: 用一句话描述文件的作用
 * @Author: Pancras
 * @Date: 2022-06-20 12:27:42
 * @LastEditors: Pancras
 * @LastEditTime: 2022-07-25 13:55:45
-->
<template>
  <el-tooltip effect="dark"
              :content="tooltipContent"
              placement="bottom">
    <el-button class="d2-ml-0 d2-mr btn-text can-hover"
               type="text"
               @click="handleClickMessageSelf">
      <el-badge v-if="messageLength > 0"
                :max="99"
                :value="messageLength"
                :is-dot="messageLength === 0">
        <d2-icon :name="'bell'"
                 style="font-size: 17px" />
      </el-badge>
      <d2-icon v-else
               name="bell"
               style="font-size: 17px;" />
    </el-button>
  </el-tooltip>
</template>

<script>
import util from '@/libs/util' // 系统工具类
import { _GetSysMessageUnreceivedSelf } from '@/api/admin/message' // 广播消息接口(API)
export default {
  data () {
    return {
      messageLength: null // 未读消息条数
    }
  },

  mounted () {
    this.getMessageUnreceivedSelfData() // 获取分页数据
    window.setInterval(this.getMessageUnreceivedSelfData, 2 * 60 * 1000) // 定时器 每分钟获取一次数据
  },

  methods: {

    /**
     * @name: handleClick
     * @description: 打开个人广播消息页面
     */
    handleClickMessageSelf () {
      this.$router.push({
        name: 'message'
      })
    },

    /**
     * getMessageData
     * @description 获取消息数据
     */
    getMessageUnreceivedSelfData () {
      if (util.getToken() && util.getToken() !== 'undefined') {
        // 调用 API 获取数据
        _GetSysMessageUnreceivedSelf('0')
          .then(res => {
            if (res) {
              // 获取个人未读消息条数
              this.messageLength = res
            } else {
              this.messageLength = 0
            }
          })
      }
    }
  },

  computed: {
    // 鼠标悬停显示消息条数
    tooltipContent () {
      return this.messageLength === 0
        ? '消息中心'
        : `${this.messageLength} 条未读消息`
    }
  }
}
</script>
