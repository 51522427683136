/*
 * @Description: 用户登陆接口
 * @Author: Pancras
 * @Date: 2019-10-24 14:17:46
 * @LastEditors: Pancras
 * @LastEditTime: 2021-11-02 23:31:45
 */
import request from '@/plugin/axios'
import setting from '@/setting'
import QS from 'qs'
import cookies from '@/libs/util.cookies'

const SCOPE = 'server'
const TENANT_ID = cookies.get('tenantId') || setting.server.tenantId
const AUTHORIZATION = setting.server.authorization

export function reqGet (data) {
  return request({
    url: '/code',
    method: 'get',
    data
  })
}

export function reqCheck (data) {
  return request({
    url: '/code/check',
    method: 'post',
    params: data
  })
}

export function _LoginByAccount (data) {
  const grantType = 'password'
  let dataObj = QS.stringify({ 'username': data.username, 'password': data.password })
  return request({
    url: '/auth/oauth/token',
    headers: {
      isToken: false,
      'TENANT-ID': TENANT_ID,
      'Authorization': AUTHORIZATION
    },
    method: 'post',
    params: { randomStr: data.randomStr, code: data.code, grant_type: grantType },
    data: dataObj

  })
}

export function refreshToken (refreshToken) {
  const grantType = 'refresh_token'
  return request({
    url: '/auth/oauth/token',
    headers: {
      'isToken': false,
      'TENANT-ID': TENANT_ID,
      'Authorization': AUTHORIZATION
    },
    method: 'post',
    params: { refresh_token: refreshToken, grant_type: grantType, scope: SCOPE }
  })
}

export function _Logout () {
  return request({
    url: '/auth/token/logout',
    method: 'delete'
  })
}

export function _GetUserInfo () {
  return request({
    url: '/admin/user/info',
    method: 'get'
  })
}

export function _GetUserMenu () {
  return request({
    url: '/admin/menu',
    method: 'get'
  })
}

export function _RefreshToken (refreshToken) {
  const grantType = 'refresh_token'
  return request({
    url: '/auth/oauth/token',
    headers: {
      'isToken': false,
      'TENANT-ID': TENANT_ID,
      'Authorization': AUTHORIZATION
    },
    method: 'post',
    params: { refresh_token: refreshToken, grant_type: grantType, scope: SCOPE }
  })
}

export function _GetByUsername (username) {
  return request({
    url: '/admin/user/username/' + username,
    method: 'get'
  })
}
