/*
 * @Description: 公共组件注册
 * @Author: Pancras
 * @Date: 2019-09-27 21:36:34
 * @LastEditors: Pancras
 * @LastEditTime: 2022-05-26 10:58:39
 */
import Vue from 'vue'

import d2Container from './d2-container'
import d2ContainerFrame from './d2-container-frame'

// 注意 有些组件使用异步加载会有影响
Vue.component('d2-container', d2Container)
Vue.component('d2-container-frame', d2ContainerFrame)
Vue.component('d2-icon', () => import('./d2-icon'))
Vue.component('d2-icon-svg', () => import('./d2-icon-svg/index.vue'))
Vue.component('d2-count-up', () => import('./d2-count-up'))
Vue.component('d2-wechat', () => import('./d2-wechat/index.vue'))
Vue.component('d2-markdown', () => import('./d2-markdown'))
Vue.component('image-upload', () => import('./image-upload'))
Vue.component('d2-back', () => import('./d2-back'))

Vue.component('icon-select', () => import('./icon-select/index.vue'))
Vue.component('area-select', () => import('./area-select/index.vue'))
Vue.component('dept-tree', () => import('./dept-tree/index.vue'))
Vue.component('dept-list', () => import('./dept-list/index.vue'))
Vue.component('tinymce-editor', () => import('./tinymce-editor/index.vue'))
Vue.component('detail-show', () => import('./detail-show/index.vue'))
// 商城组件
Vue.component('mall-image-upload', () => import('./mall-image-upload'))
