import util from '@/libs/util'
export default {
  namespaced: true,
  state: {
    info: {}, // 用户基本信息
    roles: [], // 用户角色
    permissions: [], // 用户权限
    menu: [] // 用户菜单
  },
  actions: {
    /**
     * @description 设置用户信息
     * @param {Object} context
     * @param {Array} info 用户基本信息
     */
    setInfo ({ state }, info) {
      // store 赋值
      state.info = info
    },
    /**
     * @description 设置角色信息
     * @param {Object} context
     * @param {Array} roles 用户角色
     */
    setRoles ({ state }, roles) {
      // store 赋值
      state.roles = roles
      // 使用 session 存储 roles
      util.sessions.set('roles', JSON.stringify(roles))
    },
    /**
    * @description 设置权限信息
    * @param {Object} context
    * @param {Array} permissions 用户权限
    */
    setPermissions ({ state }, permissions) {
      // store 赋值
      state.permissions = permissions
      // 使用 session 存储 permissions
      util.sessions.set('permissions', JSON.stringify(permissions))
    },
    /**
    * @description 设置权限信息
    * @param {Object} context
    * @param {Array} permissions 用户权限
    */
    setMenu ({ state, dispatch }, menu) {
      // store 赋值
      state.menu = menu
      // 持久化
      dispatch('d2admin/db/set', {
        dbName: 'sys',
        path: 'user.menu',
        value: menu,
        user: true
      }, { root: true })
    },
    /**
     * @description 从数据库取用户数据
     * @param {Object} context
     */
    loadMenu ({ state, dispatch }) {
      return new Promise(async resolve => {
        // 用户菜单
        state.menu = await dispatch('d2admin/db/get', {
          dbName: 'sys',
          path: 'user.menu',
          defaultValue: {},
          user: true
        }, { root: true })
        // end
        resolve(state.menu)
      })
    }
  }
}
