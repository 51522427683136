/*
 * @Description: 系统配置文件
 * @LastEditors: Pancras
 * @LastEditTime: 2022-07-21 15:58:02
 */
export default {
  // 服务器配置
  server: {
    // 加密用 key 应和服务器保持一致
    encryptionKey: 'asspasspasspassp',
    // 租户ID
    tenantId: '1',
    // 认证中心加密字符串，应和数据库配置的的客户端保持一致
    authorization: 'Basic YXNzcDphc3Nw'
  },
  // 快捷键
  // 支持快捷键 例如 ctrl+shift+s
  hotkey: {
    search: {
      open: 's',
      close: 'esc'
    }
  },
  // 侧边栏默认折叠状态
  menu: {
    asideCollapse: false,
    // 使用 ID 过滤后台菜单,只记录父级菜单 ID
    fillterIds: [
      1000, // 系统
      1100, // 权限
      1200, // 维护
      1300, // 监控
      1400, // 支付
      1500, // 微信
      2000, // 农场
      2100, // 设置
      2200, // 农场
      2600, // 任务
      2700, // 大屏
      2800, // 统计
      2900, // 采集
      3000, // 商城
      3100, // 会员
      3200, // 订单
      3300, // 商品
      3400, // 促销
      3500, // 店铺
      3600, // 分销
      3700, // 运营
      3800, // 统计
      3900, // 设置
      4000, // 协同
      4100, // 设置
      4200, // 审批
      4300, // 待办
      6000, // 车辆
      6100, // 车辆
      6200, // 审核
      6300, // 订单
      6400, // 统计
      7400, // 资讯
      7000, // 新闻
      7100, // 设置
      7200, // 新闻
      9000, // 开发
      9100, // 开发
      9200, // 开发
      9300, // 开发
      5000, // 交易中心
      5100, // 交易中心-设置
      8000, // 棉花溯源
      8100, // 溯源管理
      23655, // 云仓
      23656, // 会员
      23658, // 货物
      23660, // 仓库
      23661, // 设置

      // 31409, // 云仓
      // 31410, // 会员
      // 31411, // 货物
      // 31412, // 仓库
      // 31413, // 设置
      15000, // 支付
      40211, // 测试农机
      44451, // 测试订单
      42121, // 测试农机
      42146 // 测试订单
    ]
  },
  // 在读取持久化数据失败时默认页面
  page: {
    opened: [
      {
        name: 'index',
        fullPath: '/index',
        meta: {
          title: '首页',
          auth: false
        }
      }
    ]
  },
  // 菜单搜索
  search: {
    enable: true
  },
  website: {
    name: '新疆绿洲驼铃农业发展有限公司', // 网站名称
    support: { // 技术支持
      name: '', // 公司名称
      phone: '', // 联系电话
      adress: '' // 地址
    }
  },
  // 是否默认开启页面切换动画
  transition: {
    active: true
  }
}
