export default {
  namespaced: true,
  state: {
    isLock: false, // 是否锁定屏幕
    lockPasswd: ''// 锁屏密码
  },
  actions: {
    /**
    * @description 设置屏幕锁定状态
    * @param {Object} context
    */
    setLock ({ state, dispatch }) {
      // store 赋值
      state.isLock = true
      // 持久化
      dispatch('d2admin/db/set', {
        dbName: 'sys',
        path: 'user.isLock',
        value: true,
        user: true
      }, { root: true })
    },
    /**
    * @description  清除锁屏状态
    * @param {Object} context
    */
    clearLock ({ state, dispatch }) {
      // store 赋值
      state.isLock = false
      // 持久化
      dispatch('d2admin/db/set', {
        dbName: 'sys',
        path: 'user.isLock',
        value: false,
        user: true
      }, { root: true })
    },
    /**
    * @description  设置锁屏密码
    * @param {Object} context
    * @param {String} lockPasswd 锁屏密码
    */
    setLockPasswd ({ state, dispatch }, lockPasswd) {
      // store 赋值
      state.lockPasswd = lockPasswd
      // 持久化
      dispatch('d2admin/db/set', {
        dbName: 'sys',
        path: 'user.lockPasswd',
        value: lockPasswd,
        user: true
      }, { root: true })
    },
    /**
    * @description  加载锁屏设置
    * @param {Object} context
    * @param {String} lockPasswd 锁屏密码
    */
    load ({ state, dispatch }, lockPasswd) {
      return new Promise(async resolve => {
        // 基本信息
        state.isLock = await dispatch('d2admin/db/get', {
          dbName: 'sys',
          path: 'user.isLock',
          defaultValue: false,
          user: true
        }, { root: true })
        // 锁屏密码
        state.lockPasswd = await dispatch('d2admin/db/get', {
          dbName: 'sys',
          path: 'user.lockPasswd',
          defaultValue: '',
          user: true
        }, { root: true })
        // end
        resolve()
      })
    }
  }
}
