/*
* @Description: 租户管理接口文件
* @Author: Pancras
* @Date: 2019-10-21 10:13:41
 * @LastEditors: Pancras
 * @LastEditTime: 2019-11-06 12:13:30
*/
import request from '@/plugin/axios'

/**
* @name: _AddTenant
* @description: 新增租户
* @param {Object} 参数名 数据传输对象
*/
export function _AddTenant (TenantDto) {
  return request({
    url: '/admin/tenant',
    method: 'post',
    data: TenantDto
  })
}

/**
* @name: _DelTenant
* @description: 根据ID 删除租户
* @param {Number} id 租户 ID
*/
export function _DelTenant (id) {
  return request({
    url: '/admin/tenant/' + id,
    method: 'delete'
  })
}
/**
* @name: _PutTenant
* @description: 更新租户信息
* @param {Object} TenantDto 数据传输对象
*/
export function _PutTenant (TenantDto) {
  return request({
    url: '/admin/tenant',
    method: 'put',
    data: TenantDto
  })
}

/**
* @name: _GetTenant
* @description: 根据ID 获取租户信息
* @param {Number} id 租户 ID
*/
export function _GetTenant (id) {
  return request({
    url: '/admin/tenant/' + id,
    method: 'get'
  })
}

/**
 * @name: _GetTenantNoLogin
 * @description: 根据ID 获取租户信息
 * @param {Number} id 租户 ID
 */
export function _GetTenantNoLogin (id) {
  return request({
    url: '/admin/tenant/noLogin/' + id,
    method: 'get'
  })
}

/**
* @name: _GetTenantPage
* @description: 获取租户分页数据
 * @param {Object} page 分页对象
* @param {Object} TenantVo 查询参数[TenantVo]
*/
export function _GetTenantPage (page, TenantVo) {
  let params = { ...page, ...TenantVo }
  return request({
    url: '/admin/tenant/page',
    method: 'get',
    params: params
  })
}

/**
 * @name: _GetTenantList
 * @description: 查询全部有效的租户
 */
export function _GetTenantList () {
  return request({
    url: '/admin/tenant/list',
    method: 'get'
  })
}

// 导出租户 API
export default {
  _AddTenant, _DelTenant, _PutTenant, _GetTenant, _GetTenantPage, _GetTenantList
}
