import router from '@/router'
import util from '@/libs/util'
import { frameInRoutes, frameOutRoutes, errorPageRoute } from '@/router/routes'

/**
 * 根据菜单生成路由表
 * @param {*} asyncRouterMap
 * 如果菜单是超链接会按以下原则配置:
 * 1.path 配置为:'/菜单id'
 * 2.component配置为:views/system/iframe
 * 3.meta 配置 title 及 src 属性
 */
function convertRouter (asyncMenus) {
  if (util.isNull(asyncMenus)) {
    return []
  }
  const routers = []
  asyncMenus.forEach(menu => {
    const { id, name, path, keepAlive } = menu
    // 备份 path
    let src = path
    // 是否是外部连接
    const isUrl = /^https:\/\/|http:\/\//.test(path)
    if (!util.isNull(path)) {
      const router = {
        name: util.pathToCamel(path),
        path: isUrl ? '/' + id : path,
        component (resolve) {
          if (isUrl) {
            require([`@/views/system/frame`], resolve)
          } else {
            require([`@/views${path}.vue`], resolve)
          }
        },
        children: [],
        meta: {
          title: name, // 选项卡名称
          auth: true, // 权限校验
          cache: keepAlive === 0, // 是否缓存
          src: isUrl ? src : ''// 外部连接
        }
      }
      routers.push(router)
    }
  })
  return routers
}

/**
 * @description 过滤菜单,仅保留可访问菜单
 * @param {JsonArray} menu 服务端 Menu
 */
function fillterMenu (menus) {
  let list = []
  menus.forEach(menu => {
    if (!util.isNull(menu.children)) {
      let tem = fillterMenu(menu.children)
      list.push(...tem)
    } else {
      list.push(menu)
    }
  })
  return list
}

export default {
  namespaced: true,
  state: {
    // 异步路由（后台获取）
    asyncRoutes: []
  },
  actions: {
    /**
     * 根据菜单生成路由表
     * @param {Object} context
     * @param {*} menu 用户菜单
     */
    generatorRoutes ({ state, commit }, menu) {
      // 过滤菜单
      let fillteredMenu = fillterMenu(menu)
      // 将菜单格式化为路由
      let asyncRoutes = convertRouter(fillteredMenu)
      // 更新 State
      state.asyncRoutes = asyncRoutes
      // 更新框架内显示菜单
      frameInRoutes[0].children.push(...asyncRoutes)
      // 将路由添加到路由表,注意 404 页面放在最后
      let finalRoutes = [].concat(frameInRoutes, frameOutRoutes, errorPageRoute)
      router.$addRoutes(finalRoutes)
      // 初始化页面池子
      commit('d2admin/page/init', [].concat(frameInRoutes), { root: true })
    }
  }
}
