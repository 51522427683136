import util from '@/libs/util'
import { _GetDictItemList } from '@/api/admin/dict'// API
/**
 * dictType 及 getters 命名应和数据字典保持一致
 * 数据库(marital_status) -> Store(maritalStatus)
 */
export default {
  namespaced: true,
  state: {
    dict: [] // 数据字典
  },
  getters: {
    /**
    * @description 返回字典类别
    * @param {*} state vuex state
    */
    dictType (state) {
      return Array.from(new Set(state.dict.map((item) => util.toCamel(item.type))))
    },
    /**
    * @description 根据名称获取字典
    * @param {*} state vuex state
    */
    getDict: (state) => (ditcName) => {
      return state.dict.filter((item) => { return item.type === util.toUnderLine(ditcName) })
    },
    /**
    * @description 返回性别字典
    * @param {*} state vuex state
    */
    gender (state) {
      return state.dict.filter((item) => { return item.type === 'gender' })
    },
    /**
    * @description 返回民族字典
    * @param {*} state vuex state
    */
    nation (state) {
      return state.dict.filter((item) => { return item.type === 'nation' })
    },
    /**
    * @description 返回学历字典
    * @param {*} state vuex state
    */
    education (state) {
      return state.dict.filter((item) => { return item.type === 'education' })
    },
    /**
    * @description 返回婚姻状态字典
    * @param {*} state vuex state
    */
    maritalStatus (state) {
      return state.dict.filter((item) => { return item.type === 'marital_status' })
    },
    /**
    * @description 返回政治面貌字典
    * @param {*} state vuex state
    */
    politicalLandscape (state) {
      return state.dict.filter((item) => { return item.type === 'political_landscape' })
    },
    /**
    * @description 返回租户状态字典
    * @param {*} state vuex state
    */
    statusType (state) {
      return state.dict.filter((item) => { return item.type === 'status_type' })
    },
    /**
    * @description 返回授权模式字典
    * @param {*} state vuex state
    */
    grantTypes (state) {
      return state.dict.filter((item) => { return item.type === 'grant_types' })
    },
    /**
    * @description 返回Quartz任务类型字典
    * @param {*} state vuex state
    */
    jobType (state) {
      return state.dict.filter((item) => { return item.type === 'job_type' })
    },
    /**
    * @description 返回Quartz任务运行状态状态字典
    * @param {*} state vuex state
    */
    jobExecuteStatus (state) {
      return state.dict.filter((item) => { return item.type === 'job_execute_status' })
    },
    /**
    * @description 返回Quartz任务状态状态字典
    * @param {*} state vuex state
    */
    jobStatus (state) {
      return state.dict.filter((item) => { return item.type === 'job_status' })
    },
    /**
    * @description 返回Quartz任务错失执行周期字典
    * @param {*} state vuex state
    */
    misfirePolicy (state) {
      return state.dict.filter((item) => { return item.type === 'misfire_policy' })
    },
    /**
    * @description 返回密钥类型字典
    * @param {*} state vuex state
    */
    socialType (state) {
      return state.dict.filter((item) => { return item.type === 'social_type' })
    },
    /**
    * @description 返回公共参数类型字典
    * @param {*} state vuex state
    */
    paramType (state) {
      return state.dict.filter((item) => { return item.type === 'param_type' })
    },
    /**
    * @description 返回公共参数状态字典
    * @param {*} state vuex state
    */
    paramStatus (state) {
      return state.dict.filter((item) => { return item.type === 'param_status' })
    },
    /**
    * @description 返回验证码开关字典
    * @param {*} state vuex state
    */
    captchaFlagTypes (state) {
      return state.dict.filter((item) => { return item.type === 'captcha_flag_types' })
    },
    /**
    * @description 返回前端密码加密字典
    * @param {*} state vuex state
    */
    encFlagTypes (state) {
      return state.dict.filter((item) => { return item.type === 'enc_flag_types' })
    },
    /**
    * @description 返回微信消息回复字典
    * @param {*} state vuex state
    */
    responseType (state) {
      return state.dict.filter((item) => { return item.type === 'response_type' })
    },
    /**
    * @description 返回微信订阅状态字典
    * @param {*} state vuex state
    */
    subscribe (state) {
      return state.dict.filter((item) => { return item.type === 'subscribe' })
    },
    /**
    * @description 返回请假状态字典
    * @param {*} state vuex state
    */
    leaveStatus (state) {
      return state.dict.filter((item) => { return item.type === 'leave_status' })
    },
    /**
    * @description 返回用户状态字典
    * @param {*} state vuex state
    */
    userStatus (state) {
      return state.dict.filter((item) => { return item.type === 'user_status' })
    },
    /**
    * @description 返回支付类型字典
    * @param {*} state vuex state
    */
    channelId (state) {
      return state.dict.filter((item) => { return item.type === 'channel_id' })
    },
    /**
    * @description 返回渠道状态字典
    * @param {*} state vuex state
    */
    channelStatus (state) {
      return state.dict.filter((item) => { return item.type === 'channel_status' })
    },
    /**
    * @description 返回订单支付状态字典
    * @param {*} state vuex state
    */
    orderStatus (state) {
      return state.dict.filter((item) => { return item.type === 'order_status' })
    },
    /**
    * @description 返回日志类型字典
    * @param {*} state vuex state
    */
    logType (state) {
      return state.dict.filter((item) => { return item.type === 'log_type' })
    },

    /**
    * @description 灌溉类型
    * @param {*} state vuex state
    */
    irrigationType (state) {
      return state.dict.filter((item) => { return item.type === 'irrigation_type' })
    },
    /**
     * @description 土地类型
     * @param {*} state vuex state
     */
    landType (state) {
      return state.dict.filter((item) => { return item.type === 'land_type' })
    },
    /**
     * @description 完成状态
     * @param {*} state vuex state
     */
    completeType (state) {
      return state.dict.filter((item) => { return item.type === 'complete_type' })
    },
    /**
     * @description 新闻是否置顶
     * @param {*} state vuex state
     */
    isTopType (state) {
      return state.dict.filter((item) => { return item.type === 'is_top_type' })
    },
    /**
     * @description 新闻是否设置焦点
     * @param {*} state vuex state
     */
    isFocusType (state) {
      return state.dict.filter((item) => { return item.type === 'is_focus_type' })
    },
    /**
     * @description 发布状态
     * @param {*} state vuex state
     */
    publishFlag (state) {
      return state.dict.filter((item) => { return item.type === 'publish_flag' })
    },
    /**
     * @description 新闻发布状态
     * @param {*} state vuex state
     */
    publishStatusType (state) {
      return state.dict.filter((item) => { return item.type === 'publish_status_type' })
    },
    /**
     * @description 农事记录分类
     * @param {*} state vuex state
     */
    farmingType (state) {
      return state.dict.filter((item) => { return item.type === 'farming_type' })
    },

    /**
     * @description 接单状态
     * @param {*} state vuex state
     */
    receiveType (state) {
      return state.dict.filter((item) => { return item.type === 'receive_type' })
    },

    /**
     * @description 种植认为流程状态
     * @param {*} state vuex state
     */
    plantFlowType (state) {
      return state.dict.filter((item) => { return item.type === 'plant_flow_type' })
    },

    /**
     * @description 活动类型
     * @param {*} state vuex state
     */
    activityTypeInfo (state) {
      return state.dict.filter((item) => { return item.type === 'activity_type_info' })
    },

    /**
     * @description 证件类型
     * @param {*} state vuex state
     */
    certificateType (state) {
      return state.dict.filter((item) => { return item.type === 'certificate_type' })
    },

    /**
     * @description 审核状态
     * @param {*} state vuex state
     */
    auditStatus (state) {
      return state.dict.filter((item) => { return item.type === 'audit_status_type' })
    },

    /**
     * @description 交易中心审核状态
     * @param {*} state vuex state
     */
    tradingAuditStatus (state) {
      return state.dict.filter((item) => { return item.type === 'trading_audit_status' })
    },

    /**
     * @description 灾害类型标签
     * @param {*} state vuex state
     */
    disasterFlag (state) {
      return state.dict.filter((item) => { return item.type === 'disaster_flag' })
    },

    /**
     * @description 角色类型
     * @param {*} state vuex state
     */
    roleType (state) {
      return state.dict.filter((item) => { return item.type === 'role_type' })
    },
    /**
    * @description 返回商品状态字典
    * @param {*} state vuex state
    */
    goodsStatus (state) {
      return state.dict.filter((item) => { return item.type === 'goods_status' })
    },
    /**
    * @description 返回商品审核状态字典
    * @param {*} state vuex state
    */
    goodsAuth (state) {
      return state.dict.filter((item) => { return item.type === 'goods_auth' })
    },
    /**
    * @description 返回商品类型状态字典
    * @param {*} state vuex state
    */
    goodsType (state) {
      return state.dict.filter((item) => { return item.type === 'goods_type' })
    },
    /**
     * @description 返回店铺状态字典
     * @param {*} state vuex state
     */
    storeDisable (state) {
      return state.dict.filter((item) => { return item.type === 'store_disable' })
    },
    /**
     * @description 返回店铺经营类目字典
     * @param {*} state vuex state
     */
    goodsManagementCategorys (state) {
      return state.dict.filter((item) => { return item.type === 'goods_management_category' })
    },
    /**
     * @description 返回结算单状态类目字典
     * @param {*} state vuex state
     */
    billStatus (state) {
      return state.dict.filter((item) => { return item.type === 'bill_status' })
    },
    /**
     * @description 返回订单类型类目字典
     * @param {*} state vuex state
     */
    orderType (state) {
      return state.dict.filter((item) => { return item.type === 'order_type' })
    },
    /**
     * @description 返回意见反馈类型类目字典
     * @param {*} state vuex state
     */
    feedbackType (state) {
      return state.dict.filter((item) => { return item.type === 'feedback_type' })
    },
    /**
     * @description 返回订单状态类目字典
     * @param {*} state vuex state
     */
    orderState (state) {
      return state.dict.filter((item) => { return item.type === 'order_state' })
    },
    /**
     * @description 返回售后状态类目字典
     * @param {*} state vuex state
     */
    afterSaleStatus (state) {
      return state.dict.filter((item) => { return item.type === 'after_sale_status' })
    },
    /**
     * @description 返回售后类型类目字典
     * @param {*} state vuex state
     */
    afterSaleType (state) {
      return state.dict.filter((item) => { return item.type === 'after_sale_type' })
    },
    /**
     * @description 返回交易投诉状态类目字典
     * @param {*} state vuex state
     */
    complaintStatus (state) {
      return state.dict.filter((item) => { return item.type === 'complaint_status' })
    },
    /**
     * @description 返回付款状态类目字典
     * @param {*} state vuex state
     */
    payStatus (state) {
      return state.dict.filter((item) => { return item.type === 'pay_status' })
    },
    /**
     * @description 返回土壤类型
     * @param {*} state vuex state
     */
    soilType (state) {
      return state.dict.filter((item) => { return item.type === 'soil_type' })
    },
    /**
     * @description 返回采集表单项类型
     * @param {*} state vuex state
     */
    formFieldType (state) {
      return state.dict.filter((item) => { return item.type === 'form_field_type' })
    },
    /**
     * @description 返回采集表单类型
     * @param {*} state vuex state
     */
    bizType (state) {
      return state.dict.filter((item) => { return item.type === 'biz_type' })
    },
    /**
     * @description 返回采集诉求状态
     * @param {*} state vuex state
     */
    appealStatus (state) {
      return state.dict.filter((item) => { return item.type === 'appeal_status' })
    },
    /**
     * @description 返回支付方式类目字典
     * @param {*} state vuex state
     */
    payMethod (state) {
      return state.dict.filter((item) => { return item.type === 'pay_method' })
    },
    /**
     * @description 返回大屏类目字典
     * @param {*} state vuex state
     */
    screenType (state) {
      return state.dict.filter((item) => { return item.type === 'screen_type' })
    },
    /**
     * @description 车辆类型
     * @param {*} state vuex state
     */
    vehicleType (state) {
      return state.dict.filter((item) => { return item.type === 'vehicle_type' })
    },
    /**
     * @description 驾驶证类型
     * @param {*} state vuex state
     */
    driversLicense (state) {
      return state.dict.filter((item) => { return item.type === 'drivers_license' })
    },
    /**
     * @description 产品形态
     * @param {*} state vuex state
     */
    productForm (state) {
      return state.dict.filter((item) => { return item.type === 'product_form' })
    },
    /**
     * @description 产品通用名称
     * @param {*} state vuex state
     */
    generalProductName (state) {
      return state.dict.filter((item) => { return item.type === 'general_product_name' })
    },
    /**
     * @description 农药剂型
     * @param {*} state vuex state
     */
    pesticideFormulation (state) {
      return state.dict.filter((item) => { return item.type === 'pesticide_formulation' })
    },
    /**
     * @description 农药类别
     * @param {*} state vuex state
     */
    pesticideCategory (state) {
      return state.dict.filter((item) => { return item.type === 'pesticide_category' })
    },
    /**
     * @description 个人认证角色
     * @param {*} state vuex state
     */
    memberAuthType (state) {
      return state.dict.filter((item) => { return item.type === 'member_auth_type' })
    },
    /**
     * @description 单位认证角色
     * @param {*} state vuex state
     */
    factoryAuthType (state) {
      return state.dict.filter((item) => { return item.type === 'factory_auth_type' })
    },
    /**
     * @description 公检加工类型
     * @param {*} state vuex state
     */
    inspectionMachType (state) {
      return state.dict.filter((item) => { return item.type === 'inspection_mach_type' })
    },
    /**
     * @description 公检轧工质量
     * @param {*} state vuex state
     */
    inspectionRollingQuality (state) {
      return state.dict.filter((item) => { return item.type === 'inspection_rolling_quality' })
    },
    /**
     * @description 公检断裂比强度
     * @param {*} state vuex state
     */
    inspectionBreakingStrength (state) {
      return state.dict.filter((item) => { return item.type === 'inspection_breaking_strength' })
    },
    /**
     * @description 公检马克隆值
     * @param {*} state vuex state
     */
    inspectionMicronaire (state) {
      return state.dict.filter((item) => { return item.type === 'inspection_micronaire' })
    },
    /**
     * @description 公检长度整齐度
     * @param {*} state vuex state
     */
    inspectionLengthUniformity (state) {
      return state.dict.filter((item) => { return item.type === 'inspection_length_uniformity' })
    },
    /**
     * @description 公检长度级
     * @param {*} state vuex state
     */
    inspectionLength (state) {
      return state.dict.filter((item) => { return item.type === 'inspection_length' })
    },
    /**
     * @description 公检颜色级
     * @param {*} state vuex state
     */
    inspectionColor (state) {
      return state.dict.filter((item) => { return item.type === 'inspection_color' })
    },
    /**
     * @description 公捡单位
     * @param {*} state vuex state
     */
    inspectionUnit (state) {
      return state.dict.filter((item) => { return item.type === 'inspection_unit' })
    },
    /**
     * @description 轧花厂信用等级
     * @param {*} state vuex state
     */
    creditLevel (state) {
      return state.dict.filter((item) => { return item.type === 'credit_level' })
    },
    /**
     * @description 云仓产品属性
     * @param {*} state vuex state
     */
    cktype (state) {
      return state.dict.filter((item) => { return item.type === 'cktype' })
    },
    /**
     * @description 云仓库位类型
     * @param {*} state vuex state
     */
    kwtype (state) {
      return state.dict.filter((item) => { return item.type === 'kwtype' })
    },
    /**
     * @description 云仓库位属性
     * @param {*} state vuex state
     */
    kwshuxing (state) {
      return state.dict.filter((item) => { return item.type === 'kwshuxing' })
    },
    /**
     * @description
     * @param {*} state vuex state
     */
    cancelReasonType (state) {
      return state.dict.filter((item) => { return item.type === 'cancel_reason_type' })
    }
  },
  actions: {
    /**
    * init
    * @description  初始化数据字典
    * @param {Object} commit vuex commit
    */
    init ({ commit, dispatch }) {
      return new Promise((resolve, reject) => {
        // 服务端获取字典
        _GetDictItemList().then((res) => {
          // 持久化
          dispatch('d2admin/db/set', {
            dbName: 'sys',
            path: 'const.dict',
            value: res,
            user: true
          }, { root: true })
          //  提交变更
          commit('Set', res)
          resolve(res)
        }).catch(err => {
          reject(err)
        })
      })
    },
    /**
    * @description 从持久化数据读取数据字典
    * @param {Object} state vuex commit and dispatch
    */
    load ({ commit, dispatch }) {
      return new Promise(async resolve => {
        // 给 state.dict 赋值
        commit('Set', await dispatch('d2admin/db/get', {
          dbName: 'sys',
          path: 'const.dict',
          defaultValue: [],
          user: true
        }, { root: true }))
        // end
        resolve()
      })
    }
  },
  mutations: {
    /**
    * Set
    * @description 设置数据字典
    * @param {Object} state vuex state
    * @param {Array}  dict  字典数据
    */
    Set (state, dict) {
      state.dict = dict
    }
  }
}
