/*
 * @Description: 站点管理接口文件
 * @Author: wxt
 * @Date: 2022-08-02 16:27:02
 * @LastEditors: wxt
 * @LastEditTime: 2022-08-02 16:27:02
 */
import request from '@/plugin/axios' // axios 请求插件

/**
 * _AddCmsSite
 * @description 新增站点
 * @param {Object} CmsSiteDto 数据传输对象
 */
export function _AddCmsSite (cmsSiteDto) {
  return request({
    url: '/cms/cms-site',
    method: 'post',
    data: cmsSiteDto
  })
}

/**
 * _DelCmsSite
 * @description 删除站点
 * @param {Number} id 主键
 */
export function _DelCmsSite (id) {
  return request({
    url: '/cms/cms-site/' + id,
    method: 'delete'
  })
}

/**
 * _PutCmsSite
 * @description 更新站点
 * @param {Object} CmsSiteDto 数据传输对象
 */
export function _PutCmsSite (cmsSiteDto) {
  return request({
    url: '/cms/cms-site',
    method: 'put',
    data: cmsSiteDto
  })
}

/**
 * _GetCmsSite
 * @description 查询站点信息 [权限内]
 * @param {Number} id 主键
 */
export function _GetCmsSite (id) {
  return request({
    url: '/cms/cms-site/' + id,
    method: 'get'
  })
}

/**
 * _GetCmsSiteVo
 * @description 查询站点信息 [VO] [权限内]
 * @param {Number} id 主键
 */
export function _GetCmsSiteVo (id) {
  return request({
    url: '/cms/cms-site/' + id + '/vo',
    method: 'get'
  })
}

/**
 * _GetCmsSitePage
 * @description 分页查询站点分页信息 [VO] [权限内]
 * @param {Object} page 分页对象
 * @param {Object} cmsSiteVo 查询参数 [CmsSiteVo]
 */
export function _GetCmsSitePage (page, cmsSiteVo) {
  return request({
    url: '/cms/cms-site/page',
    method: 'get',
    params: { ...page, ...cmsSiteVo }
  })
}

/**
 * _ListCmsSite
 * @description 查询站点列表
 */
export function _ListCmsSite () {
  return request({
    url: '/cms/cms-site/list',
    method: 'get'
  })
}

export default {
  _AddCmsSite, _DelCmsSite, _PutCmsSite, _GetCmsSite, _GetCmsSiteVo, _GetCmsSitePage, _ListCmsSite
}
