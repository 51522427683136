/*
 * @Description: Axios 接口请求插件
 * @Author: Pancras
 * @Date: 2019-10-24 14:17:51
 * @LastEditors: Pancras
 * @LastEditTime: 2022-07-25 13:48:12
 */
import store from '@/store'
import router from '@/router'
import axios from 'axios'
import Qs from 'qs'
import { Message } from 'element-ui'
import util from '@/libs/util'
import setting from '@/setting'
import errorCode from '@/const/errorCode'
import cookies from '@/libs/util.cookies'

// 返回其他状态码(否则自定义 errorCode 不会被捕捉)
axios.defaults.validateStatus = status => {
  return status >= 200 && status <= 500 // 默认的
}

// 创建一个错误
// type:0:正常 9:异常
function errorCreate (msg, requestConfig) {
  const error = new Error(msg)
  errorLog(error, requestConfig)
  throw error
}

// 记录和显示错误
function errorLog (error, requestConfig) {
  console.log(requestConfig)
  // 添加到日志
  store.dispatch('d2admin/log/push', {
    url: requestConfig.url,
    method: requestConfig.method,
    params: requestConfig.params,
    type: '9',
    message: error.message,
    stack: error.stack,
    info: ''
  })
  // 打印到控制台
  if (process.env.NODE_ENV === 'development') {
    util.log.danger('>>>>>> Error >>>>>>')
    console.log(error)
  }
  // 显示提示
  Message({
    message: error.message,
    type: 'error',
    duration: 5 * 1000
  })
}

// 创建一个 axios 实例
const service = axios.create({
  baseURL: process.env.VUE_APP_API,
  timeout: 30000, // 请求超时时间
  // 参数序列化
  paramsSerializer: function (params) {
    return Qs.stringify(params, { arrayFormat: 'repeat', allowDots: true, skipNulls: true })
  }
})

// 请求拦截器
service.interceptors.request.use(
  config => {
    // 从 session 中获取 token
    const token = util.getToken()
    // 从配置文件获取租户 ID
    // const TENANT_ID = setting.server.tenantId
    const TENANT_ID = cookies.get('tenantId') || setting.server.tenantId
    // 判断该请求是否需要携带 token
    const isToken = (config.headers || {}).isToken === false
    // 如果需要携带 token
    if (token && token !== 'undefined' && !isToken) {
      config.headers['Authorization'] = 'Bearer ' + token.accessToken
    }
    // 每个请求添加租户 ID
    config.headers['TENANT-ID'] = TENANT_ID
    return config
  },
  (error, request) => {
    // 记录日志
    errorLog(error, request.config)
    // Promise 失败
    return Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  response => {
    const status = Number(response.status) || 200
    const message = response.data.msg || errorCode[status] || errorCode['default']
    // 当前操作没有权限
    if (status === 401) {
      // 创建并记录错误日志
      errorCreate(message, response.config)
      store.dispatch('d2admin/account/logout').then(() => {
        router.push({ path: '/login' })
      })
      return
    }

    // Token 过期
    if (status === 424) {
      // 退出登录
      store.dispatch('d2admin/account/logout').then(() => {
        router.push({ path: '/login' })
      })
      return
    }

    // 接口请求成功,但是服务器返回自定义错误
    if (status !== 200 || response.data.code === 1) {
      // 创建并记录错误日志
      errorCreate(message, response.config)
      // Promise 失败
      return Promise.reject(new Error(message))
    }
    // 服务端自定义返回码 0:成功 1:失败
    const { code } = response.data
    // 无 code 为非 token 请求,可能是登录或验证码等请求
    if (code === undefined) {
      return response
    } else {
      // 接口请求返回服务端数据,而不是response
      return response.data.data
    }
  },
  error => {
    errorLog(error, {
      url: null,
      method: null,
      params: null
    })
    return Promise.reject(error)
  }
)

export default service
