<!--
 * @Description: 退出登陆/完善个人信息/完善组织信息
 * @Author: Pancras
 * @Date: 2019-10-21 10:13:41
 * @LastEditors: Pancras
 * @LastEditTime: 2022-01-06 10:17:12
 -->
<template>
  <div>
    <!-- 头像 -->
    <el-avatar v-if="info.avatar"
               :src="info.avatar"
               size="small"
               class="avatar"></el-avatar>
    <el-avatar v-else
               size="small"
               class="avatar"> {{info.username.charAt(0).toUpperCase()}} </el-avatar>
    <!-- 头像 -->
    <el-dropdown size="medium"
                 class="d2-mr">
      <span class="btn-text">
        {{ info.username ? info.username : "未登录" }}
        <i class="el-icon-arrow-down el-icon--right"></i>
      </span>
      <el-dropdown-menu slot="dropdown">
        <!-- 修改个人信息 -->
        <el-dropdown-item icon="el-icon-user"
                          @click.native="handleUserInfoEdit">
          个人信息
        </el-dropdown-item>
        <!-- 修改个人信息 -->
        <!-- 修改组织信息 -->
        <el-dropdown-item icon="el-icon-office-building"
                          @click.native="handleDeptInfoEdit">
          组织信息
        </el-dropdown-item>
        <!-- 修改修改组织信息 -->
        <!-- 退出登陆 -->
        <el-dropdown-item @click.native="handleLogout"
                          icon="el-icon-switch-button"
                          divided>
          注 销
        </el-dropdown-item>
        <!-- 退出登陆 -->
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
  methods: {
    ...mapActions('d2admin/account', [
      'logout'
    ]),

    /**
     * @description 完善个人信息-修改密码
     */
    handleUserInfoEdit () {
      this.$router.push({
        path: '/info'
      })
    },

    /**
     * @name: handleDeptInfoEdit
     * @description: 完善组织信息
     */
    handleDeptInfoEdit () {
      this.$router.push({
        path: '/deptInfo'
      })
    },

    /**
     * @name: handleLogout
     * @description: 登出
     */
    handleLogout () {
      this.logout({
        vm: this,
        confirm: true
      })
    }
  },

  computed: {
    // 获取当前登陆用户信息
    ...mapState('d2admin/user', [
      'info'
    ])
  }
}
</script>
<style lang="scss" scoped>
.avatar {
  margin: 0px 5px;
  vertical-align: middle;
}
</style>
