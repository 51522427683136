<!--
 * @Description: 用一句话描述文件的作用
 * @Author: Pancras
 * @Date: 2022-12-01 17:24:13
 * @LastEditors: Pancras
 * @LastEditTime: 2023-02-16 19:41:07
-->
<template>
  <div v-show="visible">
    <el-select v-model="curSiteId"
               placeholder="请选择"
               @change="handleClick"
               size="mini">
      <el-option v-for="item in options"
                 :key="item.id"
                 :disabled="item.id === curSiteId"
                 :label="item.name"
                 :value="item.id">
      </el-option>
    </el-select>
  </div>
</template>

<script>
import { _ListCmsSite } from '@/api/cms/cms-site'
import { mapActions, mapState } from 'vuex'

export default {
  data () {
    return {
      curSiteId: 1,
      options: [],
      visible: false
    }
  },
  computed: {
    ...mapState('d2admin', {
      siteId: state => state.site.siteId // 选择的站点ID
    })
  },
  watch: {
    siteId: {
      handler (val) {
        this.curSiteId = val
      },
      immediate: true
    },
    visible (val) {
      if (val) {
        this.getSiteData()
      }
    },
    $route: {
      handler (val) {
        this.visible = !!val.path.includes('/cms')
      },
      // 深度观察监听
      immediate: true,
      deep: true
    }
  },

  methods: {
    ...mapActions('d2admin/site', ['setSite']),
    getSiteData () {
      _ListCmsSite().then(res => {
        this.options = res
      }).catch(() => {
        this.$notify.error('站点数据加载失败')
      })
    },
    handleClick () {
      this.setSite(this.curSiteId)
    }
  }
}
</script>
