const idCard = {}

/**
 * @description 从身份证中获取性别
 * @param {String} idCard 身份证号码
 */
idCard.getSex = function (idCard) {
  let sex = 0
  if (idCard.length === 15) {
    sex = (idCard[14] % 2 === 0) ? '1' : '2'
  } else {
    sex = (idCard[16] % 2 === 0) ? '1' : '2'
  }
  return sex
}

/**
 * @description 从身份证中获取出生年月
 * @param {String} idCard 身份证号码
 */
idCard.getBirthday = function (idCard) {
  let birthday = ''
  if (idCard.length === 15) {
    birthday = `19${idCard.substring(6, 8)}-${idCard.substring(8, 10)}-${idCard.substring(10, 12)}`
  } else {
    birthday = `${idCard.substring(6, 10)}-${idCard.substring(10, 12)}-${idCard.substring(12, 14)}`
  }
  return birthday
}

/**
 * @description 从身份证中获取年龄
 * @param {String} idCard 身份证号码
 */
idCard.getAge = function (idCard) {
  // 获取出生日期
  let birthday = idCard.getBirthday(idCard)
  // 获取当前日期
  let now = new Date()
  let month = now.getMonth() + 1
  let day = now.getDate()
  let age = now.getFullYear() - birthday.substring(0, 4) - 1
  if (birthday.substring(5, 7) < month || (birthday.substring(5, 7) === month && birthday.substring(8, 10) <= day)) {
    age++
  }
  return age
}

/**
 * @description 从身份证中获取县市
 * @param {String} idCard 身份证号码
 */
idCard.getArea = function (idCard) {
  return ''
}

export default idCard
