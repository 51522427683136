/*
 * @Description: 系统菜单接口文件
 * @Author: Pancras
 * @Date: 2019-10-21 10:13:41
 * @LastEditors: Pancras
 * @LastEditTime: 2019-11-25 19:40:49
 */
import request from '@/plugin/axios'

/**
 * @name: _AddMenu
 * @description: 新增菜单
 * @param {Object} MenuDto 数据传输对象
 */
export function _AddMenu (MenuDto) {
  return request({
    url: '/admin/menu',
    method: 'post',
    data: MenuDto
  })
}

/**
 * @name: _DelMenu
 * @description: 删除菜单
 * @param {Number} id 菜单 ID
 */
export function _DelMenu (id) {
  return request({
    url: '/admin/menu/' + id,
    method: 'delete'
  })
}

/**
 * @name: _PutMenu
 * @description: 更新菜单
 * @param {Object} MenuDto 数据传输对象
 * @return: 返回类型 注释
 */
export function _PutMenu (MenuDto) {
  return request({
    url: '/admin/menu',
    method: 'put',
    data: MenuDto
  })
}

/**
 * @name: _GetMenu
 * @description: 根据 ID获取菜单
 * @param {Number} id 菜单 ID
 */
export function _GetMenu (id) {
  return request({
    url: '/admin/menu/' + id,
    method: 'get'
  })
}

/**
 * @name: _GetUserMenu
 * @description: 获取用户菜单
 */
export function _GetUserMenu () {
  return request({
    url: '/admin/menu',
    method: 'get'
  })
}

/**
 * @name: _GetMenuTree
 * @description: 获取菜单属性集合
 * @param {Object} query 角色 参数
 */
export function _GetMenuTree () {
  return request({
    url: '/admin/menu/tree',
    method: 'get'
  })
}

/**
 * @name: _GetRoleMenuTree
 * @description: 根据角色 ID 获取权限菜单
 * @param {Number} roleId 角色 ID
 */
export function _GetRoleMenuTree (roleId) {
  return request({
    url: '/admin/menu/tree/' + roleId,
    method: 'get',
    params: roleId
  })
}
// 导出 API
export default {
  _AddMenu, _DelMenu, _PutMenu, _GetMenu, _GetUserMenu, _GetMenuTree, _GetRoleMenuTree
}
