/**
 * 自定义过滤器
 * 过滤器说明:
 *   1,Vue2.x废弃了内置过滤器，允许自定义过滤器
 *   2,过滤器可以用在两个地方：双花括号插值和 v-bind 表达式 (后者从 2.1.0+ 开始支持)
 *   3,过滤器应该被添加在 JavaScript 表达式的尾部，由“管道”符号指示
 * 使用示例:
 *   1,双花括号插值: {{ message | capitalize }}
 *   2,v-bind 绑定: <div v-bind:id="rawId | formatId"></div>
 */
import util from '@/libs/util' // 工具类
import dayjs from 'dayjs' // 日期工具类

//  时间戳格式滤器
let dateFilter = (value, format) => {
  if (util.isNull(format)) {
    format = 'YYYY-MM-DD'
  }
  return dayjs(value).format(format)
}

/**
 * 身份证解密并混淆
 * @description 将身份证解密并混淆中间位
 * @param {String} value Base64 加密字符串
 */
let idCardConfusingFilter = value => {
  const idCard = util.security.decrypt(value)
  return idCard.replace(/^(.{4})(?:\d+)(.{4})$/, '$1**********$2')
}

/**
 * 身份证解密
 * @description 将身份证解密
 * @param {String} value Base64 加密字符串
 */
let idCardDecryptFilter = value => {
  return util.security.decrypt(value)
}

/**
 * 获取字典名称
 * @description 根据字典类型及字典值获取字典名称
 * @param {Object} value 字典值
 * @param {String} type  字典类型
 */
let dictFilter = (value, type) => {
  return util.getDictLabel(value, type)
}

/**
 * 获取行政区划名称
 * @description 根据行政区划代码获取名称
 * @param {String} value  行政区划代码
 */
let areaFilter = value => {
  return util.getAreaName(value)
}

/**
 * 格式化货币数字
 * @description 格式化货币数字
 * @param {String} value  货币数字
 * @param unit 单位
 */
let currencyFilter = (value, unit) => {
  return util.unitPrice(value, unit)
}

let overFlowFilter = value => {
  if (!value) return ''
  if (value.length > 30) {
    return value.slice(0, 30) + '...'
  }
  return value
}

let toCamel = (value) => {
  return util.toCamel(value)
}

export default {
  dateFilter,
  idCardConfusingFilter,
  idCardDecryptFilter,
  dictFilter,
  areaFilter,
  currencyFilter,
  overFlowFilter,
  toCamel
}
